import { createStore } from "vuex";
import { fetchData } from "./utils/fetch";

const collectionInfoMap = [
  "name",
  "titel",
  "text",
  "prof",
  "models",
  "styling",
  "foto",
  "makeup",
  "insta",
  "website",
  "mail",
];

function getCollectionObj(collection) {
  let collections = {};

  for (let i = 0; i < collection.length; ++i) {
    collections[collectionInfoMap[i]] = collection[i] || "";
  }

  return collections;
}

const store = createStore({
  state: {
    collections: [],
  },
  mutations: {
    async setData(state) {
      const { values } = await fetchData();

      for (let collection of values) {
        state.collections.push(getCollectionObj(collection));
      }
    },
  },
  getters: {
    collections(state) {
      return state.collections;
    },
  },
});

export default store;
