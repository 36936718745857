<template>
  <!-- <div id="nav">
    <router-link to="/">Show</router-link>
    <router-link to="/kollektionen">Gallery</router-link>
    <router-link to="/info">Info</router-link>
  </div> -->
  <router-view />
  <!-- background image -->
</template>

<style>

@import url("./main.css");
</style>
