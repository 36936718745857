<template>
  <div class="home">
    <!-- <Countdown :target="new Date('Oct 13, 2021 19:30:00')" /> -->

    <!-- nav section -->
    <div id="nav">
      <div
        class="button"
        @click="showVideo = !showVideo"
        :class="{ activeButton: showVideo }"
      >
        Show
      </div>
      <div
        class="button"
        @click="showGallery = !showGallery"
        :class="{ activeButton: showGallery }"
      >
        Gallery
      </div>
      <div
        class="button"
        @click="showInfo = !showInfo"
        :class="{ activeButton: showInfo }"
      >
        Info
      </div>
      <div
        class="button"
        @click="showImpressum = !showImpressum"
        :class="{ activeButton: showImpressum }"
      >
        Imprint
      </div>
    </div>

    <div class="scroll-hint" :class="{ activeHint: showHint }">
      <img src="../img/scroll_hinweis.png" alt="" />
    </div>

    <!-- background image -->
    <div class="background-image">
      <img src="../img/bg.png" alt="" />
    </div>

    <!-- gallery section -->
    <div class="collection-images" v-if="showGallery">
      <div v-for="collection in collections" :key="collection.name">
        <router-link
          :to="{
            name: 'Collection',
            params: {
              name: collection.name,
            },
          }"
        >
          <img :src="getPreviewImg(collection.name)" />
        </router-link>
      </div>
    </div>

    <!-- video section -->
    <div class="video" :class="{ active: showVideo }">
      <!-- <div class="placeholder">
        <div class="placeholder-bg"></div>
        <div class="video-text">Coming soon</div>
      </div> -->
      <div class="video-inner">
        <div class="video_eins">
          <iframe
            src="https://player.vimeo.com/video/670679649?h=698b63c4bf&autoplay=1&color=ffffff&title=0&byline=0&portrait=0&autopause=false"
            frameborder="0"
            allow="fullscreen; picture-in-picture"
          ></iframe>
        </div>

        <div class="video_zwei">
          <iframe
            src="https://player.vimeo.com/video/670685252?h=698b63c4bf&autoplay=1&color=ffffff&title=0&byline=0&portrait=0&autopause=false"
            frameborder="0"
            allow="fullscreen; picture-in-picture"
          ></iframe>
        </div>

        <div class="video_drei">
          <iframe
            src="https://player.vimeo.com/video/670686955?h=698b63c4bf&autoplay=1&color=ffffff&title=0&byline=0&portrait=0&autopause=false"
            frameborder="0"
            allow="fullscreen; picture-in-picture"
          ></iframe>
        </div>

        <div class="video_vier">
          <iframe
            src="https://player.vimeo.com/video/670686628?h=698b63c4bf&autoplay=1&color=ffffff&title=0&byline=0&portrait=0&autopause=false"
            frameborder="0"
            allow="fullscreen; picture-in-picture"
          ></iframe>
        </div>
      </div>
    </div>

    <!-- info section -->
    <div class="info" :class="{ active: showInfo }">
      <div class="info-text">
        APLUS 12; das ist die zwölfte Modenschau der
        <a
          target="_blank"
          href="https://design.haw-hamburg.de/home/das-department-design"
          >Armgartstraße</a
        >. Jährlich stattfindend und eigenständig in interdisziplinärer
        Zusammenarbeit, von Studierenden des Department Design der Haw Hamburg
        entwickelt und organisiert. Die Modenschau präsentiert die Bachelor und
        Master Abschlusskollektionen des Studienganges und auch die Design Kurse
        zeigen etwas. In diesem Jahr wurde die Modenschau aus der Hochschule auf
        die Straßen Hamburgs verlegt. Geplant in einer Zeit als die Stadt noch
        im Lockdown steckte und der Spaziergang der einzige Kontakt nach draußen
        war.
      </div>

      <div class="links">
        <p>Vergangene Shows</p>
        <a target="_blank" href="https://11.aplus-show.com/">A+11</a> /
        <a target="_blank" href="https://vimeo.com/431004561"
          >Aˆ<span style="opacity:0;">3</span>3</a
        >
        /
        <a target="_blank" href="https://www.youtube.com/watch?v=_PxT7WeeM_c"
          >A+10</a
        >
        / <a target="_blank" href="https://vimeo.com/220448455">A+8</a> /
        <a target="_blank" href="https://vimeo.com/194950755">A+7</a>
      </div>

      <div class="links">
        <a
          target="_blank"
          href="https://www.instagram.com/fashion.design.hamburg/"
          >Instagram</a
        >
        <a target="_blank" href="https://www.facebook.com/AplusFashionShow"
          >Facebook</a
        >
      </div>
    </div>
    <!-- impressum section -->
    <div class="impressum" :class="{ active: showImpressum }">
      <div class="impressum-text">
        Projektleitung; Dejvi Haxhi, Charlotte Golz <br />
        Grafik; Sandy Richter, Elena Afli <br />
        Website; Gina Bartzok <br />
        Foto/Film; Justice Hirsch, Amelie Heinrich, Hanifatus Wibowo, Lena
        Niehusen <br />
        Regie; Dejvi Haxhi, Charlotte Golz <br />
        Schnitt; Jannes Becherer <br />
        Musik; Korbinian Scheffold <br />
        Backstage; Leonie Winterhalter, Sylvia Kollmorgen <br />
        Technik; Leonore Culemann, Gunnar Remus <br />
        Casting; Angelique Dins, Hawi Akrawi, Johanna Eßler <br />
        Presse; Jan-Niklas Jessen, Ella Gödecken <br />
        Finanzen; Kea Uhlig, Luka Schu <br />
        Hygiene; Vanessa Heibel <br />
        Party; Maya Schwarz, Maria Przybylski <br />
        Betreuung; Jürgen Frisch, Kai Dünhölter <br />
        <br />
        Angaben gemäß § 5 TGM: <br />
        HAW Hamburg Fakultät Design, Medien und Information Finkenau 35, <br />
        22081 Hamburg, <br />
        info@aplus-show.com <br />
        info@haw-hamburg.de <br />
        Tel. +49 40 428 75 <br />
        <br />
        Rechtsform und gesetzliche Vertretung:<br />
        Die HAW Hamburg ist eine Körperschaft des Öffentlichen Rechts. Sie wird
        gesetzlich vertreten durch Prof. Dr. Micha Teuscher, Präsident der HAW
        Hamburg, Berliner Tor 5, D-20099 Hamburg. <br /><br />
        Zuständige Aufsichtsbehörde:<br />
        Behörde für Wissenschaft, Forschung und Gleichstellung der Freien und
        Hansestadt Hamburg Hamburger Straße 37, D-22083 Hamburg <br />
        <br />
        Umsatzsteuer­identifikations­nummer: DE 246571598 <br />
        Wir sind nicht bereit oder verpflichtet, an Streit­beilegungs­verfahren
        vor einer Verbraucher­schlichtungs­stelle teilzunehmen. <br /><br />
        Haftung für Inhalte: <br />Als Diensteanbieter sind wir gemäß § 7 Abs.1
        TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen
        verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch
        nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen
        zu überwachen oder nach Umständen zu forschen, die auf eine
        rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder
        Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen
        bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst
        ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich.
        Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese
        Inhalte umgehend entfernen. Haftung für Links: Unser Angebot enthält
        Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen
        Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine
        Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der
        jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die
        verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche
        Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der
        Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der
        verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer
        Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von
        Rechtsverletzungen werden wir derartige Links umgehend entfernen.
        Urheberrecht: Die durch die Seitenbetreiber erstellten Inhalte und Werke
        auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die
        Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung
        außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen
        Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien
        dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch
        gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber
        erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere
        werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem
        auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
        entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden
        wir derartige Inhalte umgehend entfernen.
      </div>

      <div class="links">
        <p>Sponsoren</p>
        <img src="../img/sponsoren/TC_logo_DL.png" class="sponsoren" />
        <img src="../img/sponsoren/LogoHofgarten.png" class="sponsoren" />
        <img src="../img/sponsoren/Logo.png" class="sponsoren" />
        <img src="../img/sponsoren/Schriftzug.png" class="sponsoren" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  beforeCreate() {
    if (this.$store.state.collections.length === 0) {
      this.$store.commit("setData");
    }
  },
  created() {
    setTimeout(() => {
      this.showHint = false;
      window.localStorage.setItem("hintWasPresented", true);
    }, 4000);
  },
  data() {
    return {
      getPreviewImg: (name) => {
        const folderName = name.toLowerCase().replace(" ", "_");
        return require(`../img/${folderName}/${folderName}_preview.png`);
      },
      showVideo: false,
      showInfo: false,
      showImpressum: false,
      showGallery: true,
      showHint: !window.localStorage.getItem("hintWasPresented"),
    };
  },
  computed: {
    collections() {
      return this.$store.state.collections;
    },
  },
};
</script>

<style scoped>
* {
  cursor: url("../img/ellipse.png") 15 15, default;
  cursor: -webkit-image-set(url("../img/ellipse.png") 1x) 15 15, default;
}
.home {
  cursor: url("../img/ellipse.png") 15 15, default;
  cursor: -webkit-image-set(url("../img/ellipse.png") 1x) 15 15, default;
  /* z-index: 300; */
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.background-image {
  height: 100vh;
}

.background-image img {
  top: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}

.scroll-hint {
  position: absolute;
  display: none;
  width: 200px;
  z-index: 2000;
  height: 200px;
  transition: all 0.5s;
}

.scroll-hint img {
  width: 100%;
  height: 100%;
}

.activeHint {
  display: inline;
  transition: all 0.5s;
  animation: pulse 1s infinite;
  animation-timing-function: ease-in-out;
}

@keyframes pulse {
  0% {
    transform: scale(0.9);
  }

  70% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.9);
  }
}

/* nav section */
#nav {
  user-select: none;
  display: flex;
  flex-direction: row;
  position: fixed;
  z-index: 1000;
  padding: 10px;
  font-family: var(--text-font);
  bottom: 5px;
  left: 10px;
  cursor: url("../img/ellipse.png") 15 15, pointer;
  cursor: -webkit-image-set(url("../img/ellipse.png") 1x) 15 15, pointer;
}

.button {
  backdrop-filter: blur(7px);
  text-align: center;
  text-decoration: none;
  color: white;
  /* background-color: #f8f8f8; */
  padding: 4px 20px 6px 20px;
  font-size: 1.4em;
  font-variation-settings: "wght" 100, "wdth" 50;
  transition: all 1s;
  border-radius: 50px;
  margin-right: 10px;
  box-shadow: var(--not-active);
  cursor: url("../img/ellipse.png") 15 15, pointer;
  cursor: -webkit-image-set(url("../img/ellipse.png") 1x) 15 15, pointer;
}

.button:hover {
  font-variation-settings: "wght" 100, "wdth" 100;
  border-radius: 7px;
  transition: all 1s;
  cursor: url("../img/ellipse.png") 15 15, pointer;
  cursor: -webkit-image-set(url("../img/ellipse.png") 1x) 15 15, pointer;
}

.activeButton {
  box-shadow: var(--active);
  cursor: url("../img/ellipse.png") 15 15, pointer;
  cursor: -webkit-image-set(url("../img/ellipse.png") 1x) 15 15, pointer;
}

.active {
  display: inline !important;
}

/* video section */
.video {
  z-index: 40;
  display: none;
  position: absolute;
  width: 100%;
  height: calc(100% - 7vh);
  margin-top: auto;
  margin-bottom: 7vh;
  margin-left: auto;
  margin-right: auto;
  transition: all 0.5s;
  /* border-radius: 20px; */
  /* overflow: hidden; */
}

.video-inner {
  width: 100%;
  height: 100%;
  position: relative;
}

.video_eins {
  position: absolute;
  transition: all 1s ease-in-out;
  right: 7vw;
  top: 5vh;
  width: 30vw;
  height: 35vh;
}

.video_zwei {
  position: absolute;
  transition: all 1.2s ease;
  left: 25vw;
  z-index: 30;
  top: 5vh;
  width: 35vw;
  height: 45vh;
}

.video_drei {
  position: absolute;
  transition: all 0.2s ease-out;
  left: 3vw;
  bottom: 2vh;
  width: 40vw;
  height: 60vh;
}

.video_vier {
  position: absolute;
  transition: all 0.5s ease-in;
  width: 40vw;
  height: 45vh;
  right: 2vw;
  bottom: 0;
}

iframe {
  width: 100%;
  height: 100%;
}

.placeholder {
  width: 100%;
  height: 60vh;
  text-transform: uppercase;
  font-family: Arachne;
  color: black;
  font-size: 3em;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.placeholder-bg {
  width: 100%;
  height: 100%;
  background-color: var(--green);
  filter: blur(26px);
}

.video-text {
  position: absolute;
  text-align: center;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;
}

/* gallery section */
.collection-images {
  display: none;
  z-index: 30;
  width: 100vw;
  height: 100vh;
  overflow: scroll !important;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: 10em;
  position: absolute;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  cursor: url("../img/ellipse.png") 15 15, pointer !important;
}

.collection-images::-webkit-scrollbar {
  display: none;
}

.collection-images img {
  height: 85vh;
  transition: all 1s;
}

.collection-images img:hover {
  height: 90vh;
  transition: all 0.3s;
}
.collection-images div:first-child {
  padding-left: 3em;
}

.collection-images div:last-child {
  padding-right: 3em;
}

/* info section */
p {
  margin: 0;
  color: var(--green);
}

.info {
  z-index: 20;
  display: none;
  position: absolute;
}

.info-text {
  font-family: Arachne;
  color: var(--green);
  font-size: 2em;
  padding: 20px;
  text-shadow: var(--text-glow);
}

.info-text a {
  color: var(--green);
  text-decoration: none;
  border-bottom: 0.5px solid var(--green);
  text-shadow: var(--text-glow);
}

.links {
  font-family: Arachne;
  font-size: 2em;
  margin-bottom: 4rem;
  padding: 20px;
  color: var(--green);
  text-shadow: var(--text-glow);
  cursor: url("../img/ellipse.png") 15 15, pointer;
}

.links a {
  color: var(--green);
  text-decoration: none;
  border-bottom: 0.5px solid var(--green);
  margin-right: 10px;
}

/* impressum section */

.impressum {
  z-index: 10;
  position: absolute;
  display: none;
  font-family: Arachne;
  color: var(--green);
  font-size: 1em;
  height: 100vh;
  overflow: scroll;
  padding: 20px;
  top: 0;
  text-shadow: var(--text-glow);
}

.impressum-text {
  margin: 2rem 1rem 1rem 0px;
}

.sponsoren {
  width: 5vw;
  padding-right: 30px;
}

@media (max-width: 600px) {
  #nav {
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
  }

  .video {
    width: 90vw;
    height: 100vh;
  }

  .video-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    height: 100vh;
  }

  .video_eins,
  .video_zwei,
  .video_drei,
  .video_vier {
    position: initial;
    height: 20vh;
    width: 80vw;
  }

  .placeholder {
    font-size: 2em;
  }

  .collection-images {
    height: 90vh;
  }
  .collection-images img {
    height: 80vh;
  }

  .collection-images img:first-child {
    padding-left: 0;
  }

  .info-text {
    font-size: 1em;
  }
  .links {
    font-size: 1em;
    margin-bottom: 0;
    margin-top: 0;
    padding-top: 5px;
  }
  .impressum {
    font-size: 0.8em;
  }
  .sponsoren {
    width: 30vw;
  }
}

@import url("../main.css");
</style>
