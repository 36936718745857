export function fetchData() {
  const sheetId = "1rKwwj9Nk2BCBrxtPLm-RLWXs3Au1H_s_o8yOi0svFhI";
  const baseUrl = `https://sheets.googleapis.com/v4/spreadsheets/${sheetId}/values/`;
  const range = "B1:M11";
  const majorDim = "COLUMNS";
  const key = "AIzaSyCfqZ_IL3mJPQ1tpJyfUvmdwnG6GowundI";
  const url = `${baseUrl}${range}?key=${key}&majorDimension=${majorDim}`;

  return new Promise((resolve, reject) => {
    fetch(url)
      .then((response) => resolve(response.json()))
      .catch((err) => reject(err));
  });
}
